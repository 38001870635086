import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Select,
  Option,
} from "@material-tailwind/react";

import { Container, Main } from "./styled";
import { useEffect, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";
import { UsersAPI } from "../../../apis/UsersAPI";
import { RolesAPI } from "../../../apis/RolesAPI";
import { AxiosError, isAxiosError } from "axios";
import PhoneInput from "../../../components/ui/PhoneInput";

const AddUserProtected = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { authMessage, setAuthMessage, user } = useAuth();
  let params = new URLSearchParams(location.search);
  let navigation = useNavigation();
  let isAddingUser = navigation.formData?.get("senha_confirmar") != null;
  let actionData = useActionData() as
    | { error: string; message: string; success: boolean }
    | undefined;
  const [message, setMessage] = useState<string | null>(null);
  const [roles, setRoles] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [permissionsAdded, setPermissionsAdded] = useState<string[]>([]);
  const [nivel, setNivel] = useState<string | undefined>(undefined);

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
    RolesAPI.listRoles({ token: user?.token })
      .then((res: any) => {
        setRoles(res);
      })
      .catch((err: Error | AxiosError) => {
        if (isAxiosError(err)) {
          console.log(err);
        } else {
          console.log(err);
        }
      });

    RolesAPI.listPermissions({ token: user?.token })
      .then((res: any) => {
        setPermissions(res);
      })
      .catch((err: Error | AxiosError) => {
        if (isAxiosError(err)) {
          console.log(err);
        } else {
          console.log(err);
        }
      });
  }, []);

  useEffect(() => {
    if (isAddingUser) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isAddingUser]);

  useEffect(() => {
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    } else if (actionData && actionData.message && actionData.success) {
      if (actionData.success) {
        setAuthMessage(actionData.message);
        navigate("/usuarios");
      }
    } else if (typeof actionData === "string") {
      setAuthMessage(actionData);
    }
  }, [actionData]);

  useEffect(() => {
    // console.log(permissionsAdded);
  }, [permissionsAdded]);

  return (
    <Container>
      <Main>
        <Card color="transparent" shadow={false}>
          <Form
            method="post"
            autoComplete="new-password"
            replace
            className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96 flex flex-col flex-nowrap items-center"
          >
            <div className="mb-1 flex flex-col w-full gap-6">
              <Typography variant="h6" className="-mb-3 ">
                Nível
              </Typography>
              <Select
                label="Nível"
                size="lg"
                onChange={(value) => {
                  setNivel(value);
                }}
              >
                {roles &&
                  roles?.map((role) => (
                    <Option value={role}>
                      {role.charAt(0).toUpperCase() + role.slice(1)}
                    </Option>
                  ))}
              </Select>
              <input name="nivel" value={nivel} hidden readOnly />

              <Typography variant="h6" className="-mb-3 ">
                Permissões
              </Typography>
              {permissions &&
                permissions?.map((permission) => (
                  <Checkbox
                    label={permission}
                    value={permission}
                    className="my-[-30px] overflow-visible"
                    ripple={false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setPermissionsAdded((current) => [
                          ...current,
                          e.target.value,
                        ]);
                      } else {
                        setPermissionsAdded((current) => {
                          return current.filter((item) =>
                            item === e.target.value ? false : e.target.value
                          );
                        });
                      }
                    }}
                  />
                ))}
              <input
                name="permissionsAdded"
                value={permissionsAdded}
                hidden
                readOnly
              />

              <Typography variant="h6" className="-mb-3 ">
                Nome
              </Typography>
              <Input
                name="nome"
                type="text"
                autoComplete="new-password"
                size="lg"
                placeholder="Nome da pessoa"
                className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" className="-mb-3 ">
                Email
              </Typography>
              <Input
                name="email"
                type="email"
                autoComplete="new-password"
                size="lg"
                placeholder="email@email.com"
                className=" !border-secondary focus:!border-secondary active:!border-tertiary text-black"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" className="-mb-3 ">
                Celular
              </Typography>
              <PhoneInput
                name="celular"
                autoComplete="new-password"
                size="lg"
                placeholder="(xx) xxxxx-xxxx"
                className=" !border-secondary focus:!border-secondary active:!border-tertiary text-black"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" className="-mb-3 ">
                Senha
              </Typography>
              <Input
                name="senha"
                type="password"
                autoComplete="new-password"
                size="lg"
                placeholder="********"
                className=" !border-secondary focus:!border-secondary active:!border-tertiary text-black"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" className="-mb-3 ">
                Confirmar Senha
              </Typography>
              <Input
                name="senha_confirma"
                type="password"
                autoComplete="new-password"
                size="lg"
                placeholder="********"
                className=" !border-secondary focus:!border-secondary active:!border-tertiary text-black"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
            </div>
            <Button
              type="submit"
              disabled={isAddingUser}
              className="mt-6 bg-secondary "
              fullWidth
            >
              {isAddingUser ? "Cadastrando" : "Cadastrar"}
            </Button>
            <Button
              className="mt-6 bg-transparent text-secondary"
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
          </Form>
        </Card>
      </Main>
    </Container>
  );
};

export default AddUserProtected;
