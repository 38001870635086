import { ChartBarIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  MenuItem,
  Option,
  Select,
  Slider,
  Switch,
  Typography,
} from "@material-tailwind/react";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LineGraph from "../../../components/Charts/Line/Line";
import Alarms from "./Alarms";
import { flattenNestedArray } from "../../../utils/utils";

interface MainCardProps {
  chave: string;
  index: number;
  data: any;
  dados: any;
  dadosCompare: any;
  reloadGraph: boolean;
  options: any;
  optionsCompare: any;
  availableDevices: any;
  grupoEscolhidoNome: string | undefined;
  deviceIdCompare: number;
  setDeviceIdCompare: React.Dispatch<React.SetStateAction<number>>;
}

const MainCard = ({
  chave,
  index,
  data,
  dados,
  dadosCompare,
  reloadGraph,
  options,
  optionsCompare,
  availableDevices,
  grupoEscolhidoNome,
  deviceIdCompare,
  setDeviceIdCompare,
}: MainCardProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(
    //   "flat availableDevices",
    //   flattenNestedArray(availableDevices.categories)
    // );
  }, [availableDevices]);

  useEffect(() => {
    // console.log("dados", dados);
  }, [dados]);

  useEffect(() => {
    // console.log("dados compare", dadosCompare);
  }, [dadosCompare]);

  return data && data.status && chave ? (
    <Card className="w-full" key={chave + index.toString()}>
      <CardBody key={reloadGraph.toString()}>
        <Typography variant="h5" className="w-full flex flex-col">
          {data.status[chave].nome}
        </Typography>

        <ul>
          {Object.keys(data.status[chave]).map((key2, index2) => {
            return key2 !== "nome" && key2 !== "datetime" ? (
              key2 === "controle" ? (
                <li key={chave + index.toString() + index2.toString()}>
                  <Typography variant="h6" as="div" className="mt-5">
                    Controles
                  </Typography>
                  <div className="flex flex-row">
                    {data.status[chave][key2].map(
                      (key3: any, index3: number) => {
                        return Object.keys(key3).map((key4, index4) => {
                          return key4 === "nome" ? (
                            <Typography
                              variant="h6"
                              as="div"
                              key={
                                chave +
                                index.toString() +
                                index2.toString() +
                                index3.toString() +
                                index4.toString()
                              }
                            >
                              {`${key3[key4]} ${
                                key3["unidade"] !== ""
                                  ? `(${key3["unidade"]})`
                                  : ""
                              }`}
                              {":"}
                            </Typography>
                          ) : key3[key4] === "on_off" ? (
                            <div
                              className="ml-3 flex items-center"
                              key={
                                chave +
                                index.toString() +
                                index2.toString() +
                                index3.toString() +
                                index4.toString()
                              }
                            >
                              <Switch
                                className="hover:bg-secondary"
                                defaultChecked={false}
                                // checked={key3["valor"]}
                                onClick={() => {
                                  // if (user && deviceId) {
                                  //   command({
                                  //     user: user,
                                  //     deviceId: deviceId,
                                  //     setor: "bomba",
                                  //     prop: "on_off",
                                  //     value: true,
                                  //   });
                                  // }
                                  // const newCommand = {
                                  //   ...data,
                                  //   status: {
                                  //     ...data.status,
                                  //     [key]: {
                                  //       ...data.status[key],
                                  //       [key2]: [
                                  //         {
                                  //           ...data.status[
                                  //             key
                                  //           ][key2][index3],
                                  //           valor: true,
                                  //         },
                                  //       ],
                                  //     },
                                  //   },
                                  // };
                                  // setData(newCommand);
                                }}
                              />
                            </div>
                          ) : key3[key4] === "percent" ? (
                            <div
                              className="ml-3 flex items-center"
                              key={
                                chave +
                                index.toString() +
                                index2.toString() +
                                index3.toString() +
                                index4.toString()
                              }
                            >
                              <Slider
                                className="text-secondary"
                                size="md"
                                defaultValue={50}
                                // value={key3["valor"]}
                              />
                            </div>
                          ) : (
                            <Fragment
                              key={
                                chave +
                                index.toString() +
                                index2.toString() +
                                index3.toString() +
                                index4.toString()
                              }
                            ></Fragment>
                          );
                        });
                      }
                    )}
                  </div>
                </li>
              ) : (
                <li key={chave + index.toString() + index2.toString()}>
                  <div className="flex flex-row">
                    {Object.keys(data.status[chave][key2]).map(
                      (key3, index3) => {
                        return key3 === "nome" ? (
                          <Typography
                            variant="h6"
                            as="div"
                            key={
                              chave +
                              index.toString() +
                              index2.toString() +
                              index3.toString()
                            }
                          >
                            {data.status[chave][key2][key3]}
                            {":"}
                          </Typography>
                        ) : (
                          <Typography
                            variant="paragraph"
                            as="div"
                            className="ml-1"
                            key={index3}
                          >
                            {data.status[chave][key2][key3]}{" "}
                          </Typography>
                        );
                      }
                    )}
                  </div>
                </li>
              )
            ) : key2 === "datetime" ? (
              <div
                className="flex flex-row"
                key={chave + index.toString() + index2.toString()}
              >
                <Typography variant="h6" className="mb-4 flex flex-row">
                  Atualizado em:
                  <Typography variant="paragraph" as="div" className="ml-1">
                    {new Date(data.status[chave][key2]).toLocaleString()}
                  </Typography>
                </Typography>
              </div>
            ) : (
              <Fragment
                key={chave + index.toString() + index2.toString()}
              ></Fragment>
            );
          })}
        </ul>

        {dados[index] && (
          <>
            <div className="mt-10 h-[40vh]">
              <LineGraph options={options} dados={dados[index]} data={data} />
            </div>
            <div className="mt-10">
              <label htmlFor="dispositivos" className="inline-block mb-5">
                Comparar (gráfico) com outro dispositivo:
              </label>
              <select
                name="dispositivos"
                onChange={(e) => {
                  setDeviceIdCompare(Number(e.target.value));
                }}
                className="w-full p-3 border-solid border-2 border-gray-500 rounded-md"
              >
                <option key={-1} value={"-1"}>
                  {`Nenhum`}
                </option>

                {availableDevices.categories.map(
                  (category: {
                    name: string;
                    id: number;
                    groups: Array<any>;
                  }) =>
                    category.groups.map(
                      (group: {
                        name: string;
                        id: number;
                        devices: Array<any>;
                      }) =>
                        group.devices.map(
                          (device: { id: string; name: string }) => (
                            <option
                              key={device.id}
                              value={device.id.toString()}
                            >
                              {`${category.name} > ${group.name} > ${device.name}`}
                            </option>
                          )
                        )
                    )
                )}
              </select>
            </div>
            {deviceIdCompare !== -1 ? (
              <div className="mt-10 h-[40vh]">
                <LineGraph
                  options={optionsCompare}
                  dados={dadosCompare[index]}
                  data={data}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </CardBody>
      <CardFooter className="mb-5 flex justify-center items-center">
        <Button
          className="flex items-center gap-3 bg-secondary"
          size="sm"
          onClick={() => {
            navigate("historico");
          }}
        >
          <ChartBarIcon color="white" className="h-4 w-4" />
          Ver Histórico
        </Button>
      </CardFooter>
      <Alarms
        availableDevices={availableDevices}
        data={data}
        grupoEscolhidoNome={grupoEscolhidoNome}
        deviceNome={data.status[chave].nome}
      />
    </Card>
  ) : (
    <></>
  );
};

export default MainCard;
