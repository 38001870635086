import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";

import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Select,
  Option,
} from "@material-tailwind/react";

import { Container, Main } from "./styled";
import { useEffect, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";
import { UsersAPI } from "../../../apis/UsersAPI";
import { RolesAPI } from "../../../apis/RolesAPI";
import { AxiosError, isAxiosError } from "axios";
import PhoneInput from "../../../components/ui/PhoneInput";
import { CategoryAPI } from "../../../apis/CategoryAPI";

const EditCategoryProtected = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const { authMessage, setAuthMessage, user } = useAuth();
  let params = new URLSearchParams(location.search);
  let navigation = useNavigation();
  let isEditingCategory = navigation.formData?.get("name") != null;
  let actionData = useActionData() as
    | { error: string; message: string; success: boolean }
    | undefined;
  const [message, setMessage] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
  }, []);

  useEffect(() => {
    if (isEditingCategory) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isEditingCategory]);

  useEffect(() => {
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    } else if (actionData && actionData.message && actionData.success) {
      if (actionData.success) {
        setAuthMessage(actionData.message);
        navigate("/categorias");
      }
    } else if (typeof actionData === "string") {
      setAuthMessage(actionData);
    }
  }, [actionData]);

  useEffect(() => {
    CategoryAPI.getCategory({ token: user?.token, id: categoryId })
      .then((res: any) => {
        setData(res);
        // console.log(res);
      })
      .catch((err: Error | AxiosError) => {
        console.log(err);
      });
  }, []);

  return (
    <Container>
      <Main>
        <Card color="transparent" shadow={false}>
          {data && (
            <Form
              method="post"
              autoComplete="new-password"
              replace
              className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96 flex flex-col flex-nowrap items-center"
            >
              <div className="mb-1 flex flex-col w-full gap-6">
                <Typography variant="h6" className="-mb-3 ">
                  Nome
                </Typography>
                <Input
                  name="nome"
                  type="text"
                  autoComplete="new-password"
                  size="lg"
                  placeholder="Nome da pessoa"
                  className=" !border-secondary focus:!border-secondary active:!border-tertiary !text-black"
                  labelProps={{
                    className: "before:content-none after:content-none",
                  }}
                  defaultValue={data.name}
                />
                <input name="id" value={categoryId} hidden readOnly />
              </div>
              <Button
                type="submit"
                disabled={isEditingCategory}
                className="mt-6 bg-secondary "
                fullWidth
              >
                {isEditingCategory ? "Atualizando" : "Atualizar"}
              </Button>
              <Button
                className="mt-6 bg-transparent text-secondary"
                onClick={() => navigate(-1)}
              >
                Voltar
              </Button>
            </Form>
          )}
        </Card>
      </Main>
    </Container>
  );
};

export default EditCategoryProtected;
