import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { AxiosError, isAxiosError } from "axios";
import { CategoryAPI } from "../apis/CategoryAPI";

export const adicionarCategoriaAction =
  (token: string | undefined) =>
  async ({ request }: LoaderFunctionArgs) => {
    let formData = await request.formData();
    let nome = formData.get("nome") as string | null;

    if (!token) {
      return {
        error: "Você não tem permissão para a ação",
      };
    }

    if (!nome) {
      return {
        error: "Você precisa fornecer um nome para cadastrar um usuário",
      };
    }

    try {
      let response = await cadastrarCategoria(token, nome);
      //  console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return {
        error: "Problema criar a categoria. Tente novamente",
      };
    }
  };

const cadastrarCategoria = async (token: string, nome: string) => {
  let dados = { name: nome };
  // console.log(dados);
  const response = await CategoryAPI.createCategory({
    token: token,
    dados: dados,
  })
    .then((res: any) => {
      return res;
    })
    .catch((err: Error | AxiosError) => {
      if (isAxiosError(err)) {
        return err.response?.data.message;
      }
    });
  return response;
};
