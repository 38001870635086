import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  Input,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../contexts/auth";
import { usePreloader } from "../../../../contexts/preloader";
import { useAlert } from "../../../../contexts/alert";
import ListWithIcon from "../../../../components/ListWithIcon";
import { AxiosError, isAxiosError } from "axios";
import { UsersAPI } from "../../../../apis/UsersAPI";
import { ComparisonAPI } from "../../../../apis/ComparisonAPI";

interface AlarmIndividualProps {
  alarmAttribute0: string | undefined;
  alarmMessage: string | undefined;
  setAlarmMessage: Dispatch<SetStateAction<string | undefined>>;
  alarmUsers: Array<Number>;
  setAlarmUsers: Dispatch<SetStateAction<Number[]>>;
  alarmVerifications: number | undefined;
  setAlarmVerifications: Dispatch<SetStateAction<number | undefined>>;
  alarmTolerancia: number | undefined;
  setAlarmTolerancia: Dispatch<SetStateAction<number | undefined>>;
  alarmValue1: number | undefined;
  setAlarmValue1: Dispatch<SetStateAction<number | undefined>>;
  alarmValue2: number | undefined;
  setAlarmValue2: Dispatch<SetStateAction<number | undefined>>;
  alarmAttribute: string | undefined;
  setAlarmAttribute: Dispatch<SetStateAction<string | undefined>>;
  alarmProps: string | undefined;
  setAlarmProps: Dispatch<SetStateAction<string | undefined>>;
  setAlarmDevice: Dispatch<SetStateAction<string | undefined>>;
  setAlarmGroup: Dispatch<SetStateAction<string | undefined>>;
  setAlarmCategory: Dispatch<SetStateAction<string | undefined>>;
  availableDevices: any;
  data: any;
}

const AlarmIndividual = ({
  alarmAttribute0,
  alarmMessage,
  setAlarmMessage,
  alarmUsers,
  setAlarmUsers,
  alarmVerifications,
  setAlarmVerifications,
  alarmTolerancia,
  setAlarmTolerancia,
  alarmValue1,
  setAlarmValue1,
  alarmValue2,
  setAlarmValue2,
  alarmAttribute,
  setAlarmAttribute,
  alarmProps,
  setAlarmProps,
  setAlarmDevice,
  setAlarmGroup,
  setAlarmCategory,
  availableDevices,
  data,
}: AlarmIndividualProps) => {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const { authMessage, setAuthMessage, user } = useAuth();
  const { startPreloader, setStartPreloader } = usePreloader();
  const { setMessageAlert, openAlert, setOpenAlert, setTypeAlert } = useAlert();
  const [users, setUsers] = useState<Array<any>>([]);
  const [usersToAdd, setUsersToAdd] = useState<Array<any>>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const [isCreatingAlarm, setIsCreatingAlarm] = useState<boolean>(false);

  const createAlarm = () => {
    setIsCreatingAlarm(true);
    ComparisonAPI.createComparison({
      token: user?.token,
      dados: {
        is_range: 1,
        id_1: deviceId?.toString(),
        prop_id1: alarmProps,
        attr_id1: alarmAttribute,
        min: alarmValue1,
        max: alarmValue2,
        tolerance: alarmTolerancia,
        threshold: alarmVerifications,
        percentage: 0,
        message: alarmMessage,
        users: alarmUsers,
      },
    })
      .then((res: any) => {
        // console.log("alarm created", res);
        setAlarmTolerancia(undefined);
        setAlarmVerifications(undefined);
        setAlarmMessage(undefined);
        setAlarmGroup(undefined);
        setAlarmCategory(undefined);
        setAlarmProps(undefined);
        setAlarmAttribute(undefined);
        setTypeAlert("success");
        setMessageAlert("Alarme Criado com sucesso");
        setOpenAlert(true);
      })
      .catch((err: Error | AxiosError) => {
        if (isAxiosError(err)) {
          setTypeAlert("danger");
          setMessageAlert(err.response?.data.message);
          setOpenAlert(true);
        } else {
          setTypeAlert("danger");
          setMessageAlert("Ocorreu algum erro na criação do alarme");
          setOpenAlert(true);
        }
      })
      .finally(() => {
        setIsCreatingAlarm(false);
      });
  };

  const deleteUser = (id: number) => {
    const filterUsers = users.filter((u) => u.id !== id);
    setUsers(filterUsers);
  };
  const addUser = (id: number) => {
    const filterUser = usersToAdd.filter((u) => u.id === id);
    // console.log(usersToAdd, filterUser);
    if (filterUser.length > 0) {
      setUsers((prev) => [
        ...prev,
        {
          id: filterUser[0].id,
          icons: ["delete"],
          user: filterUser[0].nome,
          actions: [() => deleteUser(filterUser[0].id)],
        },
      ]);
    }
  };

  useEffect(() => {
    if (isCreatingAlarm) {
      setStartPreloader(true);
    } else {
      setStartPreloader(false);
    }
  }, [isCreatingAlarm]);

  useEffect(() => {
    //search user
    if (userSearch !== "") {
      UsersAPI.searchUser({ token: user?.token, search: userSearch })
        .then((res: any) => {
          const filteredUser = res.data.map((usu: any) => ({
            id: usu.id,
            nome: usu.name,
            "e-mail": usu.email,
            cel: usu.phone,
            icons: ["add"],
            actions: [() => addUser(usu.id)],
          }));

          const filteredUserFinal = filteredUser.filter((u: any) => {
            let result = true;
            for (let i = 0; i < users.length; i++) {
              if (u.id === users[i].id) {
                result = false;
              }
            }
            return result;
          });

          // console.log("filteredUserFinal", filteredUserFinal, users);

          setUsersToAdd(filteredUserFinal);
        })
        .catch((err: Error | AxiosError) => {
          console.log(err);
        });
    } else {
      setUsersToAdd([]);
    }
  }, [userSearch, users]);

  useEffect(() => {
    if (users && users.length > 0) {
      let usersTemp: Array<number> = [];
      users.map((u, index) => usersTemp.push(u.id));
      setAlarmUsers(usersTemp);
    }
  }, [users]);

  return (
    <>
      <Select
        label="Props"
        value={alarmProps}
        onChange={(val) => {
          setAlarmProps(val);
          setAlarmCategory(undefined);
          setAlarmGroup(undefined);
          setAlarmDevice(undefined);
          setAlarmAttribute(undefined);
          setAlarmValue1(undefined);
          setAlarmValue2(undefined);
          setAlarmVerifications(undefined);
        }}
      >
        {Object.keys(data.props).map((propsKey: string) => (
          <Option key={propsKey} value={propsKey}>
            {data.props[propsKey].nome}
          </Option>
        ))}
      </Select>
      {alarmProps && (
        <Select
          label="Atributo"
          value={alarmAttribute}
          onChange={(val) => {
            setAlarmCategory(undefined);
            setAlarmGroup(undefined);
            setAlarmDevice(undefined);
            setAlarmValue1(undefined);
            setAlarmValue2(undefined);
            setAlarmVerifications(undefined);
            setAlarmAttribute(val);
          }}
        >
          {Object.keys(data.props[alarmProps])
            .filter((prop) => prop !== "nome")
            .map((attributeKey: string) => (
              <Option key={attributeKey} value={attributeKey}>
                {data.props[alarmProps][attributeKey].nome}
              </Option>
            ))}
        </Select>
      )}
      {alarmAttribute && (
        <>
          <Input
            label="Valor mínimo"
            name="valor"
            type="number"
            autoComplete="new-password"
            size="lg"
            min="0"
            onChange={({ target }) => {
              setAlarmValue1(
                target.value !== "" ? Math.abs(Number(target.value)) : undefined
              );
              setAlarmValue2(undefined);
              setAlarmVerifications(undefined);
            }}
          />
        </>
      )}

      {alarmValue1 && (
        <>
          <Input
            label="Valor máximo"
            name="valor2"
            type="number"
            autoComplete="new-password"
            size="lg"
            min="0"
            onChange={({ target }) => {
              setAlarmValue2(
                target.value !== "" ? Math.abs(Number(target.value)) : undefined
              );
              setAlarmVerifications(undefined);
            }}
          />
        </>
      )}
      {alarmValue2 !== undefined && (
        <>
          <Input
            label="Tolerância"
            name="tolerancia"
            type="number"
            autoComplete="new-password"
            size="lg"
            min="0"
            onChange={({ target }) => {
              setAlarmTolerancia(
                target.value !== "" ? Math.abs(Number(target.value)) : undefined
              );
              setAlarmVerifications(undefined);
            }}
          />
        </>
      )}
      {alarmTolerancia !== undefined && (
        <>
          <Input
            label="Número de verificações consecutivas para acionar alarme"
            name="verificacoes"
            type="number"
            autoComplete="new-password"
            size="lg"
            min="0"
            onChange={({ target }) => {
              setAlarmVerifications(
                target.value !== "" ? Math.abs(Number(target.value)) : undefined
              );
            }}
          />
        </>
      )}
      {alarmVerifications !== undefined && (
        <>
          <Input
            label="Mensagem alarme"
            name="mensagem"
            type="text"
            autoComplete="new-password"
            size="lg"
            onChange={({ target }) => {
              setAlarmMessage(target.value);
            }}
          />
        </>
      )}
      {alarmMessage !== undefined && alarmMessage !== "" && (
        <Card className=" w-[100%] p-5 flex flex-row flex-wrap align-middle justify-center gap-6">
          <Typography variant="h6" className="mb-4 flex flex-row  w-[100%]">
            Adicionar Usuários
          </Typography>
          <Input
            label="Buscar Usuário"
            name="buscar-usuario"
            type="text"
            autoComplete="off"
            size="lg"
            onChange={({ target }) => {
              setUserSearch(target.value);
            }}
          />
          <ListWithIcon items={usersToAdd} />
          <Typography variant="h6" className=" flex flex-row  w-[100%]">
            Usuários adicionados
          </Typography>
          {<ListWithIcon items={users} />}
        </Card>
      )}

      {alarmAttribute &&
        alarmMessage &&
        alarmMessage !== "" &&
        users.length > 0 && (
          <Button
            className="flex items-center gap-3 bg-secondary"
            size="sm"
            onClick={() => {
              createAlarm();
            }}
            disabled={isCreatingAlarm}
          >
            <ExclamationTriangleIcon color="white" className="h-4 w-4" />
            Criar Alarme
          </Button>
        )}
    </>
  );
};

export default AlarmIndividual;
