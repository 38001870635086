import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { AxiosError, isAxiosError } from "axios";
import { GroupsAPI } from "../apis/GroupsAPI";

export const adicionarGrupoAction =
  (token: string | undefined) =>
  async ({ request }: LoaderFunctionArgs) => {
    let formData = await request.formData();
    let nome = formData.get("nome") as string | null;
    let categoria = formData.get("categoria") as number | null;
    let meta = formData.get("meta") as string | null;

    if (!token) {
      return {
        error: "Você não tem permissão para a ação",
      };
    }

    if (!nome) {
      return {
        error: "Você precisa fornecer um nome para cadastrar um grupo",
      };
    }

    if (!categoria) {
      return {
        error: "Você precisa fornecer um categoria para cadastrar um grupo",
      };
    }

    if (!meta) {
      meta = "";
    }

    let parsedMeta;
    if (meta === "") {
      parsedMeta = {};
    } else {
      // console.log(meta)
      try {
        parsedMeta = JSON.parse(meta);
      } catch (err) {
        return {
          error: "Verifique os dados e a sintaxe do campo meta",
        };
      }
    }

    // console.log(parsedMeta);

    try {
      let response = await cadastrarGrupo(token, nome, categoria, parsedMeta);
      // console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return {
        error: "Problema ao criar o grupo. Tente novamente",
      };
    }
  };

const cadastrarGrupo = async (
  token: string,
  nome: string,
  categoria: number,
  meta: string
) => {
  let dados = { name: nome, category_id: categoria, meta: meta };
  // console.log(dados);
  const response = await GroupsAPI.createGroups({
    token: token,
    dados: dados,
  })
    .then((res: any) => {
      return res;
    })
    .catch((err: Error | AxiosError) => {
      if (isAxiosError(err)) {
        return err.response?.data.message;
      }
    });
  return response;
};
