import {
  Button,
  Card,
  Input,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import ListWithIcon from "../../../../components/ListWithIcon";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AlarmIndividual from "./AlarmIndividual";
import AlarmCompare from "./AlarmCompare";
import { useAuth, userProps } from "../../../../contexts/auth";
import { ComparisonAPI } from "../../../../apis/ComparisonAPI";
import { AxiosError } from "axios";
import { DeviceAPI } from "../../../../apis/DeviceAPI";
import { GroupsAPI } from "../../../../apis/GroupsAPI";
import { useDialog } from "../../../../contexts/dialog";
import { useEditAlarm } from "../../../../contexts/editAlarm";
import { isMobile } from "react-device-detect";

export interface IItems {
  id: number;
  equation: string;
  tolerance: number;
  threshold: number;
  users: Array<string>;
  icons: Array<string>;
  actions: Array<() => void>;
}

interface AlarmsProps {
  availableDevices: any;
  data: any;
  grupoEscolhidoNome: string | undefined;
  deviceNome: string | undefined;
}

const Alarms = ({
  availableDevices,
  data,
  grupoEscolhidoNome,
  deviceNome,
}: AlarmsProps) => {
  const { authMessage, setAuthMessage, user } = useAuth();
  const { deviceId } = useParams();
  const { setMessageDialog, setActionDialog } = useDialog();
  const { editAlarmEquation, setEditAlarmEquation } = useEditAlarm();
  const [alarmProps0, setAlarmProps0] = useState<string | undefined>(undefined);
  const [alarmAttribute0, setAlarmAttribute0] = useState<string | undefined>(
    undefined
  );
  const [alarmTolerancia, setAlarmTolerancia] = useState<number | undefined>(
    undefined
  );

  const [alarmType, setAlarmType] = useState<string | undefined>(undefined);
  const [alarmCategory, setAlarmCategory] = useState<string | undefined>(
    undefined
  );
  const [alarmGroup, setAlarmGroup] = useState<string | undefined>(undefined);
  const [alarmDevice, setAlarmDevice] = useState<string | undefined>(undefined);
  const [alarmProps, setAlarmProps] = useState<string | undefined>(undefined);
  const [alarmAttribute, setAlarmAttribute] = useState<string | undefined>(
    undefined
  );
  const [alarmOperator, setAlarmOperator] = useState<string | undefined>(
    undefined
  );
  const [alarmValue1, setAlarmValue1] = useState<number | undefined>(undefined);
  const [alarmValue2, setAlarmValue2] = useState<number | undefined>(undefined);
  const [alarmVerifications, setAlarmVerifications] = useState<
    number | undefined
  >(undefined);

  const [alarmMessage, setAlarmMessage] = useState<string | undefined>(
    undefined
  );

  const [alarmUsers, setAlarmUsers] = useState<Array<Number>>([]);
  const navigate = useNavigate();

  const [alarmsCreated, setAlarmsCreated] = useState<Array<IItems>>([]);

  const editAlarm = (id: number, eq: any) => {
    if (eq) {
      setEditAlarmEquation(eq);
    }
    setTimeout(() => {
      navigate(`/editaralarme/${id}`);
    }, 500);
  };

  useEffect(() => {
    // console.log("al eq", editAlarmEquation);
  }, [editAlarmEquation]);

  const deleteAlarm = (id: number) => {
    setMessageDialog(`Confirma exclusão do alarme id ${id}?`);
    setActionDialog(() => () => ConfirmDeleteAlarm(id));
  };

  const ConfirmDeleteAlarm = (id: number) => {
    ComparisonAPI.deleteComparison({
      token: user?.token,
      id: id,
    })
      .then((res: any) => {
        // console.log("deleted comparison", res);
        window.location.reload();
      })
      .catch((err: Error | AxiosError) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log("alarms created", alarmsCreated);
  }, [alarmsCreated]);

  useEffect(() => {
    if (deviceId && grupoEscolhidoNome && deviceNome) {
      ComparisonAPI.getComparison({
        token: user?.token,
        id1: deviceId,
        id2: null,
      })
        .then((res: any) => {
          // console.log("get comparison", res.data);
          if (res.data.length > 0) {
            let alarmsCreatedTemp: any = [];
            // console.log("all alarms", res.data);
            res.data.map((item: any, index: number) => {
              if (item.is_range === 0) {
                DeviceAPI.getDevice({
                  token: user?.token,
                  id: item.id_2,
                })
                  .then((res2) => {
                    // console.log("device 2", res2);

                    GroupsAPI.getGroups({
                      token: user?.token,
                      id: res2.group_id,
                    })
                      .then((res3: any) => {
                        // console.log("grupo device 2", res3);
                        !isMobile
                          ? alarmsCreatedTemp.push({
                              equation: `${grupoEscolhidoNome}
                •
                ${data.name}
                •
                ${deviceNome}
                •
                ${item.attr_id1}
                ${item.operation}
                ${res3.name}
                •
                ${res2.name}
                •
                ${item.prop_id2}
                •
                ${item.attr_id2}`,
                              id: item.id,
                              enabled: item.enabled,
                              message: item.message,
                              tolerance: item.tolerance,
                              threshold: item.threshold,
                              icons: ["edit", "delete"],
                              users: item.users,
                              actions: [editAlarm, deleteAlarm],
                            })
                          : alarmsCreatedTemp.push({
                              equation: `${grupoEscolhidoNome}
                  •
                  ${data.name}
                  •
                  ${deviceNome}
                  •
                  ${item.attr_id1}
                  ${item.operation}
                  ${res3.name}
                  •
                  ${res2.name}
                  •
                  ${item.prop_id2}
                  •
                  ${item.attr_id2}`,
                              id: item.id,
                              enabled: item.enabled,
                              message: item.message,
                              icons: ["edit", "delete"],
                              actions: [editAlarm, deleteAlarm],
                            });
                      })
                      .catch((err: any) => {
                        console.log(err);
                      })
                      .finally(() => {
                        setAlarmsCreated(alarmsCreatedTemp);
                      });
                  })
                  .catch(function (erro) {
                    console.log(erro);
                  });
              } else {
                !isMobile
                  ? alarmsCreatedTemp.push({
                      equation: `${grupoEscolhidoNome}
                  •
                  ${data.name}
                  •
                  ${deviceNome}
                  •
                  ${item.attr_id1}
                  ${item.min} < || > ${item.max}`,
                      id: item.id,
                      enabled: item.enabled,
                      message: item.message,
                      tolerance: item.tolerance,
                      threshold: item.threshold,
                      icons: ["edit", "delete"],
                      users: item.users,
                      actions: [editAlarm, deleteAlarm],
                    })
                  : alarmsCreatedTemp.push({
                      equation: `${grupoEscolhidoNome}
                  •
                  ${data.name}
                  •
                  ${deviceNome}
                  •
                  ${item.attr_id1}
                  ${item.min} < || > ${item.max}`,
                      id: item.id,
                      enabled: item.enabled,
                      message: item.message,
                      icons: ["edit", "delete"],
                      actions: [editAlarm, deleteAlarm],
                    });
                setAlarmsCreated(alarmsCreatedTemp);
              }
            });
          }
        })
        .catch((err: Error | AxiosError) => {
          console.log(err);
        });
    }
  }, [deviceId, grupoEscolhidoNome, deviceNome]);

  useEffect(() => {}, []);

  return (
    <div className="my-6 p-6 w-[100%]  flex flex-row flex-wrap align-middle justify-center gap-6 ">
      <Typography variant="h6" className="flex flex-row">
        ALARMES
      </Typography>
      <Typography variant="h6" className="flex flex-row w-[100%]">
        Alarmes criados
      </Typography>
      <ListWithIcon items={alarmsCreated} />
      <Typography variant="h6" className="flex flex-row w-[100%]">
        Criar Alarme
      </Typography>
      <Select
        key={alarmType}
        label="Tipo Alarme"
        value={alarmType}
        onChange={(val) => {
          setAlarmType(val);
          setAlarmProps0(undefined);
          setAlarmAttribute0(undefined);
          setAlarmCategory(undefined);
          setAlarmGroup(undefined);
          setAlarmDevice(undefined);
          setAlarmProps(undefined);
          setAlarmAttribute(undefined);
          setAlarmOperator(undefined);
          setAlarmValue1(undefined);
          setAlarmTolerancia(undefined);
          setAlarmVerifications(undefined);
        }}
      >
        <Option value="individual">Individual</Option>
        <Option value="compare">Comparação</Option>
      </Select>
      {alarmType === "individual" ? (
        <AlarmIndividual
          alarmAttribute0={alarmAttribute0}
          alarmMessage={alarmMessage}
          setAlarmMessage={setAlarmMessage}
          alarmUsers={alarmUsers}
          setAlarmUsers={setAlarmUsers}
          alarmVerifications={alarmVerifications}
          setAlarmVerifications={setAlarmVerifications}
          alarmTolerancia={alarmTolerancia}
          setAlarmTolerancia={setAlarmTolerancia}
          alarmValue1={alarmValue1}
          setAlarmValue1={setAlarmValue1}
          alarmValue2={alarmValue2}
          setAlarmValue2={setAlarmValue2}
          alarmAttribute={alarmAttribute}
          setAlarmAttribute={setAlarmAttribute}
          alarmProps={alarmProps}
          setAlarmProps={setAlarmProps}
          setAlarmDevice={setAlarmDevice}
          setAlarmGroup={setAlarmGroup}
          setAlarmCategory={setAlarmCategory}
          availableDevices={availableDevices}
          data={data}
        />
      ) : alarmType === "compare" ? (
        <AlarmCompare
          alarmAttribute0={alarmAttribute0}
          setAlarmAttribute0={setAlarmAttribute0}
          alarmMessage={alarmMessage}
          setAlarmMessage={setAlarmMessage}
          alarmUsers={alarmUsers}
          setAlarmUsers={setAlarmUsers}
          alarmVerifications={alarmVerifications}
          setAlarmVerifications={setAlarmVerifications}
          alarmValue1={alarmValue1}
          setAlarmValue1={setAlarmValue1}
          alarmOperator={alarmOperator}
          setAlarmOperator={setAlarmOperator}
          alarmAttribute={alarmAttribute}
          setAlarmAttribute={setAlarmAttribute}
          alarmProps={alarmProps}
          setAlarmProps={setAlarmProps}
          alarmProps0={alarmProps0}
          setAlarmProps0={setAlarmProps0}
          alarmDevice={alarmDevice}
          setAlarmDevice={setAlarmDevice}
          alarmGroup={alarmGroup}
          setAlarmGroup={setAlarmGroup}
          alarmCategory={alarmCategory}
          setAlarmCategory={setAlarmCategory}
          alarmTolerancia={alarmTolerancia}
          setAlarmTolerancia={setAlarmTolerancia}
          availableDevices={availableDevices}
          data={data}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Alarms;
