import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import { useAlert } from "./alert";
import { AuthAPI } from "../apis/AuthAPI";
import { error } from "@material-tailwind/react/types/components/input";
import { ErrorResponse, redirect } from "react-router-dom";
import { usePreloader } from "./preloader";
import {
  clearStorage,
  getStorageWithExpiry,
  setStorageWithExpiry,
} from "../local_sotrage/localStorage";
import { AxiosError, isAxiosError } from "axios";

export interface userProps {
  name: string;
  permissions: string[];
  roles: string[];
  token: string;
}

interface AuthContextType {
  user: null | userProps;
  setUser: Dispatch<SetStateAction<null | userProps>>;
  keepAuthenticated: null | boolean;
  setKeepAuthenticated: Dispatch<SetStateAction<null | boolean>>;
  authMessage: null | string;
  setAuthMessage: Dispatch<SetStateAction<null | string>>;
  login(
    keepAuthenticatedData: boolean,
    emailData: string,
    passwordData: string,
    redirectToData: string
  ): void;
  logout(): Promise<void>;
  recuperar(email: string): Promise<void>;
  reset(
    token: string,
    email: string,
    nova: string,
    novaConfirma: string
  ): Promise<void>;
  trocar(antiga: string, nova: string, novaConfirma: string): Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const { startPreloader, setStartPreloader } = usePreloader();
  const [user, setUser] = useState<null | userProps>(
    getStorageWithExpiry("user")
  );
  const [keepAuthenticated, setKeepAuthenticated] = useState<null | boolean>(
    false
  );
  const [authMessage, setAuthMessage] = useState<null | string>(null);

  const { setMessageAlert, openAlert, setOpenAlert, setTypeAlert } = useAlert();

  useEffect(() => {
    if (authMessage && authMessage !== "") {
      setTypeAlert("warning");
      setMessageAlert(authMessage);
      setOpenAlert(true);
    }
  }, [authMessage]);

  useEffect(() => {
    if (openAlert === false) {
      setAuthMessage("");
    }
  }, [openAlert]);

  const login = async (
    keepAuthenticatedData: boolean,
    emailData: string,
    passwordData: string,
    redirectToData: string
  ) => {
    setStartPreloader(true);
    const response = await AuthAPI.login({
      email: emailData,
      password: passwordData,
    })
      .then((res: any) => {
        // console.log(res);
        setUser(res);
        if (keepAuthenticatedData) {
          setStorageWithExpiry("user", res, 1296000000);
        }
        setAuthMessage("");
        return redirect(redirectToData || "/");
      })
      .catch((err: error) => {
        setAuthMessage("E-mail ou senha inválidos! Tente novamente.");
        return redirect(redirectToData || "/");
      })
      .finally(() => {
        setStartPreloader(false);
      });
    return response;
  };

  const logout = async () => {
    if (user) {
      setStartPreloader(true);
      const response = await AuthAPI.logout({
        token: user.token,
      })
        .then((res: any) => {
          // console.log(res);
          clearStorage("user");
          setUser(null);
          setKeepAuthenticated(false);
          setAuthMessage("Você deslogou da plataforma. Até logo.");
          return redirect("/");
        })
        .catch((err: error) => {
          setAuthMessage("Problema ao deslogar.");
          return redirect("/");
        })
        .finally(() => {
          setStartPreloader(false);
        });
      return response;
    }
  };

  const recuperar = async (email: string) => {
    setStartPreloader(true);
    setKeepAuthenticated(false);
    const response = await AuthAPI.recuperar({ email: email })
      .then((res: any) => {
        // console.log(res);
        if (!res.success) {
          clearStorage("user");
          setUser(null);
          setKeepAuthenticated(false);
          setAuthMessage(res.message);
          return redirect("/recuperar");
        } else {
          clearStorage("user");
          setUser(null);
          setKeepAuthenticated(false);
          setAuthMessage(
            `Um e-mail foi enviado para ${email} para redefinição de sua senha.`
          );
          return redirect("/");
        }
      })
      .catch((err: error) => {
        setAuthMessage("Problema ao recuperar a senha. Tente novamente.");
        return redirect("/recuperar");
      })
      .finally(() => {
        setStartPreloader(false);
      });
    return response;
  };

  const trocar = async (
    antiga: string | null,
    nova: string | null,
    novaConfirma: string | null
  ) => {
    if (user) {
      setStartPreloader(true);
      const response = await AuthAPI.trocar({
        token: user.token,
        antiga,
        nova,
        novaConfirma,
      })
        .then((res: any) => {
          setStartPreloader(false);
          setAuthMessage(`Sua senha foi alterada com sucesso.`);
          return redirect("/");
        })
        .catch((err: Error | AxiosError) => {
          // setAuthMessage("Falha ao trocar a senha. Tente novamente.");
          // setAuthMessage(err?.response?.data.message);
          if (isAxiosError(err)) {
            setAuthMessage(err.response?.data.message);
            // Access to config, request, and response
          }
        })
        .finally(() => {
          setStartPreloader(false);
        });
      return response;
    }
  };

  const reset = async (
    token: string,
    email: string,
    nova: string,
    novaConfirma: string
  ) => {
    setStartPreloader(true);
    const response = await AuthAPI.reset({
      token: token,
      email: email,
      password: nova,
      newPassword: novaConfirma,
    })
      .then((res: any) => {
        console.log(res);
        if (!res.success) {
          clearStorage("user");
          setUser(null);
          setKeepAuthenticated(false);
          setAuthMessage(res.message);
          return redirect("/reset");
        } else {
          clearStorage("user");
          setUser(null);
          setKeepAuthenticated(false);
          setAuthMessage(`Senha redefinida com sucesso`);
          return redirect("/");
        }
      })
      .catch((err: error) => {
        setAuthMessage("Problema ao redefinir a senha.");
        return "";
      })
      .finally(() => {
        setStartPreloader(false);
      });
    return response;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        keepAuthenticated,
        setKeepAuthenticated,
        authMessage,
        setAuthMessage,
        login,
        logout,
        recuperar,
        reset,
        trocar,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
}
