import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Select,
  Option,
  Textarea,
  CardBody,
  Switch,
  Slider,
  CardFooter,
} from "@material-tailwind/react";

import { Chart, Colors } from "chart.js";
import { Container, Main } from "./styled";
import { Fragment, useEffect, useRef, useState } from "react";
import { useAlert } from "../../../contexts/alert";
import { useAuth } from "../../../contexts/auth";
import { usePreloader } from "../../../contexts/preloader";
import { UsersAPI } from "../../../apis/UsersAPI";
import { RolesAPI } from "../../../apis/RolesAPI";
import { AxiosError, isAxiosError } from "axios";
import PhoneInput from "../../../components/ui/PhoneInput";
import { DeviceAPI } from "../../../apis/DeviceAPI";
import { CategoryAPI } from "../../../apis/CategoryAPI";
import { GroupsAPI } from "../../../apis/GroupsAPI";
import { NotificationAPI } from "../../../apis/NotificationAPI";
import LineGraph from "../../../components/Charts/Line/Line";

import { serverBaseURL } from "../../../apis/configs/axiosConfigs";
import { IconDispositivo } from "../HomeProtected/HomeProtected";
import {
  ChartBarIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import { command } from "../../../commands/commands";

import _ from "lodash";
import ListWithIcon from "../../../components/ListWithIcon";
import { fetchData } from "../../../apis/serverSentEvents/funtions";
import MainCard from "./MainCard";
import Alarms from "./Alarms";

Chart.register(Colors);

const ViewDeviceProtected = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const [deviceIdCompare, setDeviceIdCompare] = useState<number>(-1);
  const { authMessage, setAuthMessage, user } = useAuth();
  const { setStartPreloader } = usePreloader();
  let params = new URLSearchParams(location.search);
  let navigation = useNavigation();
  let isEditingDevice = navigation.formData?.get("name") != null;
  let actionData = useActionData() as
    | { error: string; message: string; success: boolean }
    | undefined;
  const [message, setMessage] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);
  const [dataCompare, setDataCompare] = useState<any>(null);
  const [grupos, setGrupos] = useState<string[]>([]);
  const [grupoEscolhido, setGrupoEscolhido] = useState<number | undefined>(
    undefined
  );
  const [grupoEscolhidoCompare, setGrupoEscolhidoCompare] = useState<
    number | undefined
  >(undefined);
  const [grupoEscolhidoNome, setGrupoEscolhidoNome] = useState<
    string | undefined
  >(undefined);
  const [dados, setDados] = useState<any>([]);
  const [dadosCompare, setDadosCompare] = useState<any>([]);
  const [reloadGraph, setReloadGraph] = useState<boolean>(false);
  const [reloadGraphCompare, setReloadGraphCompare] = useState<boolean>(false);
  const [lastDatetime, setLastaDatetime] = useState<any>();
  const [lastDatetimeCompare, setLastaDatetimeCompare] = useState<any>();
  const [availableDevices, setAvailableDevices] = useState<any>();

  const toogleShowItem = (idProp: number) => {
    let tempItems = [...dados];
    if (tempItems[0].datasets[idProp].hidden) {
      tempItems[0].datasets[idProp].hidden = false;
    } else {
      tempItems[0].datasets[idProp].hidden = true;
    }
    setDados(tempItems);
    setReloadGraph(true);
  };

  const toogleShowItem2 = (idProp: number) => {
    let tempItems = [...dadosCompare];
    if (tempItems[0].datasets[idProp].hidden) {
      tempItems[0].datasets[idProp].hidden = false;
    } else {
      tempItems[0].datasets[idProp].hidden = true;
    }
    setDadosCompare(tempItems);
    setReloadGraph(true);
  };

  const options = {
    animation: true,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      colors: {
        forceOverride: false,
      },
      legend: {
        position: "top" as const,
        onClick: (e: any, legendItem: any) => {
          toogleShowItem(legendItem.datasetIndex);
        },
      },
      title: {
        display: false,
        text: "",
      },
    },
  };

  const options2 = {
    animation: true,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      colors: {
        forceOverride: false,
      },
      legend: {
        position: "top" as const,
        onClick: (e: any, legendItem: any) => {
          toogleShowItem2(legendItem.datasetIndex);
        },
      },
      title: {
        display: false,
        text: "",
      },
    },
  };

  let dadosAtual = new Array();
  let dadosAtualCompare = new Array();

  useEffect(() => {
    setMessage(authMessage);
    setAuthMessage(null);
  }, []);

  useEffect(() => {
    if (isEditingDevice) {
      setMessage(authMessage);
      setAuthMessage(null);
    }
  }, [isEditingDevice]);

  useEffect(() => {
    if (actionData && actionData.error) {
      setAuthMessage(actionData.error);
    } else if (actionData && actionData.message && actionData.success) {
      if (actionData.success) {
        setAuthMessage(actionData.message);
        navigate("/dispositivos");
      }
    } else if (typeof actionData === "string") {
      setAuthMessage(actionData);
    }
  }, [actionData]);

  useEffect(() => {
    if (reloadGraph) {
      setReloadGraph(false);
    }
  }, [reloadGraph]);

  useEffect(() => {
    // console.log("dados", dados);
  }, [dados]);

  // const startFetchData = () => {
  //   console.log("fetching data");
  //   fetchData(
  //     dadosAtual,
  //     user,
  //     deviceId,
  //     setData,
  //     setDados,
  //     setGrupoEscolhido,
  //     setStartPreloader,
  //     setGrupos,
  //     lastDatetime,
  //     setLastaDatetime
  //   );
  // };

  const prepareNotifications = () => {
    setStartPreloader(true);
    NotificationAPI.getAvailableDevices({ token: user?.token })
      .then((res: any) => {
        setAvailableDevices(res);
      })
      .catch((err: Error | AxiosError) => {
        setStartPreloader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setStartPreloader(true);
    prepareNotifications();
    fetchData(
      dadosAtual,
      user,
      deviceId,
      deviceIdCompare === -1 ? null : deviceIdCompare,
      setData,
      setDados,
      setGrupoEscolhido,
      setStartPreloader,
      setGrupos,
      lastDatetime,
      setLastaDatetime,
      dadosAtualCompare,
      setDataCompare,
      setDadosCompare,
      setGrupoEscolhidoCompare,
      lastDatetimeCompare,
      setLastaDatetimeCompare
    );
  }, [deviceIdCompare]);

  useEffect(() => {
    // console.log("data", data);
  }, [data]);

  useEffect(() => {
    // console.log("dataCompare", dataCompare);
  }, [dataCompare]);

  useEffect(() => {
    // console.log("available devices", availableDevices);
  }, [availableDevices]);

  useEffect(() => {
    if (grupos && grupos.length > 0) {
      let gruposTemp = grupos
        .filter((gp: any, index: number) => gp.id === grupoEscolhido)
        .map((val: any) => val.name);
      if (gruposTemp.length > 0) {
        setGrupoEscolhidoNome(gruposTemp[0]);
      }
    }
  }, [grupos, grupoEscolhido]);

  return (
    <Container>
      <Main>
        {
          <Card
            color="transparent"
            shadow={false}
            className="mt-8 mb-2 w-full max-w-screen-lg flex flex-col flex-nowrap items-center"
          >
            <Button
              className="w-40 mb-6 bg-primary text-secondary"
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>
            <div className="mb-1 flex flex-row flex-wrap align-middle justify-center gap-6">
              <Typography variant="h6" className="flex flex-row">
                Nome:
                <Typography variant="paragraph" as="div" className="ml-1">
                  {data?.name}
                </Typography>
              </Typography>
              <Typography variant="h6" className="flex flex-row">
                Mac:
                <Typography variant="paragraph" as="div" className="ml-1">
                  {data?.mac}
                </Typography>
              </Typography>
              <Typography variant="h6" className="flex flex-row">
                Grupo:
                <Typography variant="paragraph" as="div" className="ml-1">
                  {grupoEscolhidoNome}
                </Typography>
              </Typography>
            </div>
            <div className="w-full mt-5 mb-1 flex flex-row flex-wrap gap-6">
              {data?.status &&
                Object.keys(data?.status).map((key, index) => {
                  return (
                    <>
                      <MainCard
                        chave={key}
                        index={index}
                        data={data}
                        dados={dados}
                        dadosCompare={dadosCompare}
                        options={options}
                        optionsCompare={options2}
                        reloadGraph={reloadGraph}
                        availableDevices={availableDevices}
                        grupoEscolhidoNome={grupoEscolhidoNome}
                        deviceIdCompare={deviceIdCompare}
                        setDeviceIdCompare={setDeviceIdCompare}
                      />
                    </>
                  );
                })}
            </div>
          </Card>
        }
      </Main>
    </Container>
  );
};

export default ViewDeviceProtected;
