import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  Input,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListWithIcon from "../../../../components/ListWithIcon";
import { UsersAPI } from "../../../../apis/UsersAPI";
import { AxiosError, isAxiosError } from "axios";
import { useAuth } from "../../../../contexts/auth";
import { NotificationAPI } from "../../../../apis/NotificationAPI";
import { ComparisonAPI } from "../../../../apis/ComparisonAPI";
import { usePreloader } from "../../../../contexts/preloader";
import { useAlert } from "../../../../contexts/alert";

interface AlarmCompareProps {
  alarmAttribute0: string | undefined;
  setAlarmAttribute0: Dispatch<SetStateAction<string | undefined>>;
  alarmMessage: string | undefined;
  setAlarmMessage: Dispatch<SetStateAction<string | undefined>>;
  alarmUsers: Array<Number>;
  setAlarmUsers: Dispatch<SetStateAction<Number[]>>;
  alarmVerifications: number | undefined;
  setAlarmVerifications: Dispatch<SetStateAction<number | undefined>>;
  alarmValue1: number | undefined;
  setAlarmValue1: Dispatch<SetStateAction<number | undefined>>;
  alarmOperator: string | undefined;
  setAlarmOperator: Dispatch<SetStateAction<string | undefined>>;
  alarmAttribute: string | undefined;
  setAlarmAttribute: Dispatch<SetStateAction<string | undefined>>;
  alarmProps: string | undefined;
  setAlarmProps: Dispatch<SetStateAction<string | undefined>>;
  alarmProps0: string | undefined;
  setAlarmProps0: Dispatch<SetStateAction<string | undefined>>;
  alarmDevice: string | undefined;
  setAlarmDevice: Dispatch<SetStateAction<string | undefined>>;
  alarmGroup: string | undefined;
  setAlarmGroup: Dispatch<SetStateAction<string | undefined>>;
  alarmCategory: string | undefined;
  setAlarmCategory: Dispatch<SetStateAction<string | undefined>>;
  alarmTolerancia: number | undefined;
  setAlarmTolerancia: Dispatch<SetStateAction<number | undefined>>;
  availableDevices: any;
  data: any;
}

const AlarmCompare = ({
  alarmAttribute0,
  setAlarmAttribute0,
  alarmMessage,
  setAlarmMessage,
  alarmUsers,
  setAlarmUsers,
  alarmVerifications,
  setAlarmVerifications,
  alarmValue1,
  setAlarmValue1,
  alarmOperator,
  setAlarmOperator,
  alarmAttribute,
  setAlarmAttribute,
  alarmProps,
  setAlarmProps,
  alarmProps0,
  setAlarmProps0,
  alarmDevice,
  setAlarmDevice,
  alarmGroup,
  setAlarmGroup,
  alarmCategory,
  setAlarmCategory,
  alarmTolerancia,
  setAlarmTolerancia,
  availableDevices,
  data,
}: AlarmCompareProps) => {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const { authMessage, setAuthMessage, user } = useAuth();
  const { startPreloader, setStartPreloader } = usePreloader();
  const { setMessageAlert, openAlert, setOpenAlert, setTypeAlert } = useAlert();
  const [users, setUsers] = useState<Array<any>>([]);
  const [usersToAdd, setUsersToAdd] = useState<Array<any>>([]);
  const [userSearch, setUserSearch] = useState<string>("");
  const [isCreatingAlarm, setIsCreatingAlarm] = useState<boolean>(false);

  const createAlarm = () => {
    setIsCreatingAlarm(true);
    ComparisonAPI.createComparison({
      token: user?.token,
      dados: {
        id_1: deviceId?.toString(),
        id_2: alarmDevice,
        prop_id1: alarmProps0,
        prop_id2: alarmProps,
        attr_id1: alarmAttribute0,
        attr_id2: alarmAttribute,
        operation: alarmOperator,
        tolerance: alarmTolerancia,
        threshold: alarmVerifications,
        percentage: 0,
        message: alarmMessage,
        users: alarmUsers,
      },
    })
      .then((res: any) => {
        // console.log("alarm created", res);
        setAlarmAttribute0(undefined);
        setAlarmProps0(undefined);
        setAlarmDevice(undefined);
        setAlarmOperator(undefined);
        setAlarmTolerancia(undefined);
        setAlarmVerifications(undefined);
        setAlarmMessage(undefined);
        setAlarmGroup(undefined);
        setAlarmCategory(undefined);
        setAlarmProps(undefined);
        setAlarmAttribute(undefined);

        setTypeAlert("success");
        setMessageAlert("Alarme Criado com sucesso");
        setOpenAlert(true);
      })
      .catch((err: Error | AxiosError) => {
        if (isAxiosError(err)) {
          setTypeAlert("danger");
          setMessageAlert(err.response?.data.message);
          setOpenAlert(true);
        } else {
          setTypeAlert("danger");
          setMessageAlert("Ocorreu algum erro na criação do alarme");
          setOpenAlert(true);
        }
      })
      .finally(() => {
        setIsCreatingAlarm(false);
      });
  };

  const deleteUser = (id: number) => {
    const filterUsers = users.filter((u) => u.id !== id);
    setUsers(filterUsers);
  };
  const addUser = (id: number) => {
    const filterUser = usersToAdd.filter((u) => u.id === id);
    // console.log(usersToAdd, filterUser);
    if (filterUser.length > 0) {
      setUsers((prev) => [
        ...prev,
        {
          id: filterUser[0].id,
          icons: ["delete"],
          user: filterUser[0].nome,
          actions: [() => deleteUser(filterUser[0].id)],
        },
      ]);
    }
  };

  useEffect(() => {
    if (isCreatingAlarm) {
      setStartPreloader(true);
    } else {
      setStartPreloader(false);
    }
  }, [isCreatingAlarm]);

  useEffect(() => {
    //search user
    if (userSearch !== "") {
      UsersAPI.searchUser({ token: user?.token, search: userSearch })
        .then((res: any) => {
          const filteredUser = res.data.map((usu: any) => ({
            id: usu.id,
            nome: usu.name,
            "e-mail": usu.email,
            cel: usu.phone,
            icons: ["add"],
            actions: [() => addUser(usu.id)],
          }));

          const filteredUserFinal = filteredUser.filter((u: any) => {
            let result = true;
            for (let i = 0; i < users.length; i++) {
              if (u.id === users[i].id) {
                result = false;
              }
            }
            return result;
          });

          // console.log("filteredUserFinal", filteredUserFinal, users);

          setUsersToAdd(filteredUserFinal);
        })
        .catch((err: Error | AxiosError) => {
          console.log(err);
        });
    } else {
      setUsersToAdd([]);
    }
  }, [userSearch, users]);

  useEffect(() => {
    if (users && users.length > 0) {
      let usersTemp: Array<number> = [];
      users.map((u, index) => usersTemp.push(u.id));
      setAlarmUsers(usersTemp);
    }
  }, [users]);

  return (
    <>
      <Card className=" w-[100%] p-5 flex flex-row flex-wrap align-middle justify-center gap-6">
        <Typography variant="h6" className="mb-4 flex flex-row  w-[100%]">
          Este Dispositivo
        </Typography>
        <Select
          label="Props"
          value={alarmProps0}
          onChange={(val) => {
            setAlarmProps0(val);
            setAlarmAttribute0(undefined);
          }}
        >
          {Object.keys(data.props).map((propsKey: string) => (
            <Option key={propsKey} value={propsKey}>
              {data.props[propsKey].nome}
            </Option>
          ))}
        </Select>
        {alarmProps0 && (
          <Select
            label="Atributo"
            value={alarmAttribute0}
            onChange={(val) => {
              setAlarmAttribute0(val);
            }}
          >
            {Object.keys(data.props[alarmProps0])
              .filter((prop) => prop !== "nome")
              .map((attributeKey: string) => (
                <Option key={attributeKey} value={attributeKey}>
                  {data.props[alarmProps0][attributeKey].nome}
                </Option>
              ))}
          </Select>
        )}
      </Card>
      <Card className=" w-[100%] p-5 flex flex-row flex-wrap align-middle justify-center gap-6">
        <Typography variant="h6" className="mb-4 flex flex-row  w-[100%]">
          Comparação e setup
        </Typography>
        {alarmAttribute0 && (
          <Select
            label="Operador"
            value={alarmOperator}
            onChange={(val) => {
              setAlarmOperator(val);
            }}
          >
            {availableDevices.operators.map((operator: string) => (
              <Option key={operator} value={operator}>
                {operator}
              </Option>
            ))}
          </Select>
        )}
        {alarmOperator && (
          <>
            <Input
              label="Tolerância"
              name="tolerancia"
              type="number"
              autoComplete="new-password"
              size="lg"
              min="0"
              onChange={({ target }) => {
                setAlarmTolerancia(
                  target.value !== ""
                    ? Math.abs(Number(target.value))
                    : undefined
                );
                setAlarmVerifications(undefined);
              }}
            />
          </>
        )}
        {alarmTolerancia !== undefined && (
          <>
            <Input
              label="Número de verificações consecutivas para acionar alarme"
              name="verificacoes"
              type="number"
              autoComplete="new-password"
              size="lg"
              min="0"
              onChange={({ target }) => {
                setAlarmVerifications(
                  target.value !== ""
                    ? Math.abs(Number(target.value))
                    : undefined
                );
              }}
            />
          </>
        )}
        {alarmVerifications !== undefined && (
          <>
            <Input
              label="Mensagem alarme"
              name="mensagem"
              type="text"
              autoComplete="new-password"
              size="lg"
              onChange={({ target }) => {
                setAlarmMessage(target.value);
              }}
            />
          </>
        )}
      </Card>
      <Card className=" w-[100%] p-5 flex flex-row flex-wrap align-middle justify-center gap-6">
        <Typography variant="h6" className="mb-4 flex flex-row  w-[100%]">
          Dispositivo de comparação
        </Typography>
        {alarmTolerancia && (
          <Select
            label="Categoria"
            value={alarmCategory}
            onChange={(val) => {
              setAlarmCategory(val);
              setAlarmGroup(undefined);
              setAlarmDevice(undefined);
              setAlarmProps(undefined);
              setAlarmAttribute(undefined);
            }}
          >
            {availableDevices?.categories?.map((category: any) => (
              <Option key={category.name} value={category.id.toString()}>
                {category.name}
              </Option>
            ))}
          </Select>
        )}
        {alarmCategory && (
          <Select
            label="Grupo"
            value={alarmGroup}
            onChange={(val) => {
              setAlarmGroup(val);
              setAlarmDevice(undefined);
              setAlarmProps(undefined);
              setAlarmAttribute(undefined);
            }}
          >
            {availableDevices.categories
              .find((el: any) => el.id === Number(alarmCategory))
              .groups?.map((group: any) => (
                <Option key={group.name} value={group.id.toString()}>
                  {group.name}
                </Option>
              ))}
          </Select>
        )}
        {alarmGroup && (
          <Select
            key={alarmDevice}
            label="Dispositivo"
            value={alarmDevice}
            onChange={(val) => {
              setAlarmDevice(val);
              setAlarmProps(undefined);
              setAlarmAttribute(undefined);
            }}
          >
            {availableDevices.categories
              .find((el: any) => el.id === Number(alarmCategory))
              .groups?.find((el: any) => el.id === Number(alarmGroup))
              .devices?.map((device: any) => (
                <Option key={device.name} value={device.id.toString()}>
                  {device.name}
                </Option>
              ))}
          </Select>
        )}
        {alarmDevice && (
          <Select
            label="Props"
            value={alarmProps}
            onChange={(val) => setAlarmProps(val)}
          >
            {availableDevices.categories
              .find((el: any) => el.id === Number(alarmCategory))
              .groups?.find((el: any) => el.id === Number(alarmGroup))
              .devices?.find((el: any) => el.id === Number(alarmDevice))
              .props.map((prop: any) => (
                <Option key={prop.name} value={prop.id}>
                  {prop.name}
                </Option>
              ))}
          </Select>
        )}
        {alarmProps && (
          <Select
            label="Atributo"
            value={alarmAttribute}
            onChange={(val) => setAlarmAttribute(val)}
          >
            {availableDevices.categories
              .find((el: any) => el.id === Number(alarmCategory))
              .groups?.find((el: any) => el.id === Number(alarmGroup))
              .devices?.find((el: any) => el.id === Number(alarmDevice))
              .props?.find((el: any) => el.id === alarmProps)
              .attributes.map((attribute: any) => (
                <Option key={attribute.name} value={attribute.id.toString()}>
                  {attribute.name}
                </Option>
              ))}
          </Select>
        )}
      </Card>

      <Card className=" w-[100%] p-5 flex flex-row flex-wrap align-middle justify-center gap-6">
        <Typography variant="h6" className="mb-4 flex flex-row  w-[100%]">
          Adicionar Usuários
        </Typography>
        <Input
          label="Buscar Usuário"
          name="buscar-usuario"
          type="text"
          autoComplete="off"
          size="lg"
          onChange={({ target }) => {
            setUserSearch(target.value);
          }}
        />
        <ListWithIcon items={usersToAdd} />
        <Typography variant="h6" className=" flex flex-row  w-[100%]">
          Usuários adicionados
        </Typography>
        {alarmDevice && <ListWithIcon items={users} />}
      </Card>
      {alarmAttribute &&
        alarmAttribute0 &&
        alarmMessage &&
        alarmMessage !== "" &&
        users.length > 0 && (
          <Button
            className="flex items-center gap-3 bg-secondary"
            size="sm"
            onClick={() => {
              createAlarm();
            }}
            disabled={isCreatingAlarm}
          >
            <ExclamationTriangleIcon color="white" className="h-4 w-4" />
            Criar Alarme
          </Button>
        )}
    </>
  );
};

export default AlarmCompare;
