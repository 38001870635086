import { useEffect, useState } from "react";
import { CategoryAPI } from "../../../apis/CategoryAPI";
import { useAuth } from "../../../contexts/auth";
import { AxiosError, isAxiosError } from "axios";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  PencilIcon,
  UserPlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import { Container, Main } from "./styled";
import { disable } from "workbox-navigation-preload";
import { useNavigate } from "react-router-dom";
import { usePreloader } from "../../../contexts/preloader";
import { useDialog } from "../../../contexts/dialog";

const CategoryProtected = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setAuthMessage } = useAuth();
  const { setStartPreloader } = usePreloader();
  const { setMessageDialog, setActionDialog } = useDialog();
  const [category, setCategory] = useState<any | null>(null);
  const [filtroBusca, setFiltroBusca] = useState("");
  const [titulos, setTítulos] = useState(["Nome", "Ações"]);

  const loadPrevPage = () => {
    if (category?.current_page > 1) {
      CategoryAPI.listCategory({
        token: user?.token,
        page: category.current_page - 1,
      })
        .then((res: any) => {
          // console.log(res);
          setCategory(res);
        })
        .catch((err: Error | AxiosError) => {
          if (isAxiosError(err)) {
            console.log(err.response?.data.message);
          }
        });
    }
  };

  const loadNextPage = () => {
    if (category?.current_page < category?.last_page) {
      CategoryAPI.listCategory({
        token: user?.token,
        page: category.current_page + 1,
      })
        .then((res: any) => {
          setCategory(res);
        })
        .catch((err: Error | AxiosError) => {
          if (isAxiosError(err)) {
            console.log(err.response?.data.message);
          }
        });
    }
  };

  const editarCategoria = (id: number) => {
    navigate(`/editarcategoria/${id}`);
  };

  const excluirCategoria = (id: number, name: string) => {
    setMessageDialog(`Confirma exclusão do categoria ${name}?`);
    setActionDialog(() => () => excluirCategoriaConfirma(id));
  };

  const excluirCategoriaConfirma = (id: number) => {
    setStartPreloader(true);
    CategoryAPI.deleteCategory({ token: user?.token, id: id })
      .then((res: any) => {
        setStartPreloader(false);
        navigate("/");
        setTimeout(() => navigate("/categorias"), 1000);
        setAuthMessage("Categoria excluída com sucesso");
      })
      .catch((err: Error | AxiosError) => {
        setStartPreloader(false);
        console.log(err);
      });
  };

  const row = (index: number, id: number, name: string) => {
    const isLast = index === category.data.length - 1;
    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
    return (
      <tr key={name}>
        <td className={classes}>
          <div className="flex items-center gap-3">
            <div className="flex flex-col">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                {name}
              </Typography>
            </div>
          </div>
        </td>

        <td className={classes}>
          <Tooltip content="Editar categoria">
            <IconButton
              variant="text"
              className="text-secondary"
              onClick={() => editarCategoria(id)}
            >
              <PencilIcon className="h-4 w-4" />
            </IconButton>
          </Tooltip>
          <Tooltip content="Excluir categoria">
            <IconButton
              variant="text"
              className="text-danger"
              onClick={() => excluirCategoria(id, name)}
            >
              <XCircleIcon className="h-6 w-6" />
            </IconButton>
          </Tooltip>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    CategoryAPI.listCategory({
      token: user?.token,
      page: null,
    })
      .then((res: any) => {
        setCategory(res);
      })
      .catch((err: Error | AxiosError) => {
        if (isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      });
  }, [user]);

  return (
    <Container>
      <Main>
        <Card className="h-full w-full">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-8 flex items-center justify-between gap-8">
              <div>
                <Typography variant="h5" color="blue-gray">
                  Categorias
                </Typography>
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                <Button
                  className="flex items-center gap-3 bg-secondary"
                  size="sm"
                  onClick={() => navigate("/adicionarcategoria")}
                >
                  <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Criar
                  Categoria
                </Button>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <div className="w-full md:w-72">
                <Input
                  label="Buscar"
                  icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  onChange={(e) => setFiltroBusca(e.target.value)}
                />
              </div>
            </div>
          </CardHeader>
          <CardBody className=" px-0">
            <table className="mt-4 w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {titulos.map((titulo) => (
                    <th
                      key={titulo}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {titulo}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {category?.data.map(
                  (
                    {
                      id,
                      name,
                    }: {
                      id: number;
                      name: string;
                    },
                    index: number
                  ) => {
                    if (filtroBusca !== "") {
                      if (name.search(filtroBusca) !== -1) {
                        return row(index, id, name);
                      }
                    } else {
                      return row(index, id, name);
                    }
                  }
                )}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Página {category?.current_page} de {category?.last_page}
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                size="sm"
                onClick={loadPrevPage}
                disabled={category?.current_page === 1}
              >
                Anterior
              </Button>
              <Button
                variant="outlined"
                size="sm"
                onClick={loadNextPage}
                disabled={category?.current_page === category?.last_page}
              >
                Próxima
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Main>
    </Container>
  );
};

export default CategoryProtected;
