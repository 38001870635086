import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineGraph2(props: {
  options: any;
  dados: any;
  data: any;
}) {
  const [newData, setNewData] = useState({
    datasets: [],
    labels: [],
    name: "",
  });

  useEffect(() => {
    // console.log("line graph data", props.dados);
    // console.log("data graphs", props.data);
    if (props.dados) {
      let dataTemp = props.dados;
      dataTemp.datasets = dataTemp.datasets.map((item: any) => {
        if (item.name === "distancia") {
          const valDistSensor = Number(
            props.data.props[props.dados.name]?.specs.distancia_sensor.valor
          );
          const altCaixa = Number(
            props.data.props[props.dados.name]?.specs.altura_caixa.valor
          );
          const raioCaixa =
            Number(
              props.data.props[props.dados.name]?.specs.diametro_caixa.valor
            ) / 2;
          return {
            name: item.name,
            label: "Nível de água (m³)",
            data: item.data.map((value: any) => {
              let nivel =
                Math.PI *
                Math.pow(raioCaixa, 2) *
                (altCaixa - (value / 1000 - valDistSensor));
              return nivel;
            }),
            fill: false,
            borderColor: item.borderColor,
          };
        } else {
          return {
            name: item.name,
            label: item.label,
            data: item.data,
            fill: false,
            borderColor: item.borderColor,
          };
        }
      });
      dataTemp.datasets = dataTemp.datasets.filter((item: any) => item.label);
      setNewData(dataTemp);
    }
  }, [props.dados, props]);

  return <Line options={props.options} data={newData} />;
}
